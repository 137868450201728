<template>
  <div class="detail">
    <div class="wrapper">
      <div style="margin-bottom: 20px;">
        <a-breadcrumb>
          <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
          <a-breadcrumb-item v-if="$route.query.cmsTypeId == 1"><a href="/new/list1">新闻公告</a></a-breadcrumb-item>
          <a-breadcrumb-item v-if="$route.query.cmsTypeId == 2"><a href="/new/list2">培训活动</a></a-breadcrumb-item>
          <a-breadcrumb-item v-if="$route.query.cmsTypeId == 3"><a href="/policy">政策解读</a></a-breadcrumb-item>
          <a-breadcrumb-item>详情</a-breadcrumb-item>
          <template #separator>
            <icon-right />
          </template>
        </a-breadcrumb>
      </div>
      <div class="detail-container" >
        <div class="detail-title">{{info.title}}</div>
        <div class="detail-p">
          <!-- <span>来源：{{info.author || '--'}}</span> -->
          <span style="margin-left: 100px;">作者：{{info.author || '--'}}</span>
        </div>
        <div v-html="info.content"></div>
        <div class="file">
          <div class="file-item" v-for="(item, index) in info.attFileList" :key="index">
            <icon-file style="margin-right: 10px; color: #474747; font-size: 18px;" />
            <div class="file-name line-text-1">{{item.rawName}}</div>
            <div class="file-btn" @click="download(item)">
              <icon-download style="margin-right: 4px; font-size: 18px;" />
              下载文件
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { Message } from '@arco-design/web-vue'
import { getArticleinfo, downLoadFile } from "@/api/common.js"

export default {
  setup(props) {
    const route = useRoute()
    const state = reactive({
      list: [],
      info: {}
    })
    const getInfo = () => {
      getArticleinfo({id: route.query.id}).then(res => {
        if (res.code === 20000) {
          state.info = res.result
        }
      })
    }
    getInfo()
    const download = (item) => {
      downLoadFile({path: item.path }).then(res => {
        console.log(res)
        const blob = new Blob([res])
        // const fileName = decodeURIComponent(res.headers['content-disposition'].split(';')[1].split('filename=')[1])
        const fileName = item.rawName
        if ('download' in document.createElement('a')) { // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else { // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
      Message.success('下载中')
    }
    return {
      ...toRefs(state),
      download
    }
  }
}
</script>

<style lang="less" scoped>
.detail {
  background: #F7F6FA;
  padding: 20px 0;
  .detail-container {
    background-color: #fff;
    border-radius: 4px;
    padding: 40px 80px;
    .detail-title {
      text-align: center;
      font-size: 22px;
      color: #333;
      padding-bottom: 20px;
      border-bottom: 1px solid #E9E9E9;
    }
    .detail-p {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999;
      font-size: 12px;
      padding: 10px 0 30px;
    }
    .file {
      margin-top: 40px;
    }
    .file-item:hover {
      background-color: #f0f3fa;
    }
    .file-item {
      cursor: pointer;
      margin-top: 10px;
      display: flex;
      align-items: center;
      height: 50px;
      background: #FAFAFA;
      border-radius: 4px;
      border: 1px solid #E9E9E9;
      padding: 0 15px;
      color: #474747;
      font-size: 14px;
      .file-name {
        flex: 1;
      }
      .file-btn {
        margin-left: 40px;
        width: 100px;
        height: 26px;
        background: #096DD9;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
